import * as React from "react";
import { BasicLayout } from "../../../layouts/BasicLayout";

export default () => {
  return (
    <BasicLayout noIndex noFollow>
      <div />
    </BasicLayout>
  );
};
